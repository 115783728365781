import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CustomizedTimeline from "../../CustomizedTimeline";
import { cloneDeep, isArray } from "lodash";
import { useLoginGoogle } from "../../../../Provider/GoogleAuthContext";
import { sortDatetimeByProperty } from "../../../../utils/datetime";
import { addColorEvent } from "../../constants";
import { getAllEvent } from "../../../../apis/crypto/event";
import TimelineModalComponent from "../../TimelineModalComponent";
import WrapDialogMUI from "../../components/WrapDialogMUI";
import { IoCloseCircle } from "react-icons/io5";
import CarouselMUI from "../../../../elements/MUI/CarouselMUI";
import { EVENT_SERVER_URL } from "../../../../configs/api";
import axios from "axios";
import Block from "../../elements/Block";
import { Button } from "@mui/material";
import ButtonMUI from "../../components/ButtonMUI";

function Event(props) {
  const { type } = props;

  const { user: activeUser } = useLoginGoogle();
  const uid = activeUser?.uid;

  const [openModalTimeline, setOpenModalTimeline] = useState(false);
  const [showTimelineNode, setShowTimelineNode] = useState(false);
  const [selectTimelineNode, setSelectTimelineNode] = useState(null);
  const [selectNodeSubInfo, setSelectNodeSubInfo] = useState(null);

  const [cryptoEvent, setCryptoEvent] = useState(null);
  // const [cryptoFollowEvent, setCryptoFollowEvent] = useState(null);
  const [cryptoEventOrigin, setCryptoEventOrigin] = useState(null);
  const [cryptoEventPage, setCryptoEventPage] = useState(1);

  useEffect(() => {
    if (!activeUser || !activeUser?.uid) {
      return;
    }

    getAllEvent(activeUser?.uid).then((rs) => {
      const events =
        rs?.data?.events.map((item) => {
          return {
            ...item,
            ...addColorEvent(),
          };
        }) || [];

      const filterEvents = events.filter((ev) => {
        return type ? ev.type === type : true;
      });

      const sortEvents = sortDatetimeByProperty(
        filterEvents,
        "datetime",
        false
      );
      console.log("sortEvents: ", sortEvents);
      setCryptoEventOrigin(sortEvents);
    });
  }, [activeUser, type]);

  useEffect(() => {
    if (cryptoEventPage > 0 && cryptoEventOrigin?.length > 0) {
      const DISPLAY_PAGE_SIZE = 5;
      const cloneEvent = cloneDeep(cryptoEventOrigin);

      const removeFollow = cloneEvent.filter((ev) => !ev.followEventId);

      const min = removeFollow.length - cryptoEventPage * DISPLAY_PAGE_SIZE;
      // setCryptoFollowEvent(cloneEvent.filter((ev) => ev.followEventId));
      setCryptoEvent(
        removeFollow.slice(min > 0 ? min : 0, removeFollow.length)
      );
    }
  }, [cryptoEventPage, cryptoEventOrigin]);

  return (
    <>
      {cryptoEvent && (
        <CustomizedTimeline
          alignLeft={true}
          title=""
          data={cryptoEvent}
          clickTimelineDot={async (item) => {
            // if (!item?.image) return;
            console.log("clickTimelineDot: ", item);
            setShowTimelineNode(!showTimelineNode);
            const newItem = { ...item };
            const follows = cryptoEventOrigin.filter(
              (fe) => fe.followEventId === newItem?._id
            );
            console.log("follows: ", follows);
            setSelectTimelineNode({ ...item, followEvents: follows });
            setSelectNodeSubInfo({ ...item, followEvents: follows });
          }}
          {...{
            openModal: openModalTimeline,
            setOpenModal: setOpenModalTimeline,
            showAddNode: true,
          }}
        />
      )}
      <TimelineModalComponent
        {...{
          openModal: openModalTimeline,
          setOpenModal: setOpenModalTimeline,
          setTimeline: setCryptoEvent,
          timeline: cryptoEvent,
          timelineFull: cryptoEventOrigin,
        }}
      />
      <WrapDialogMUI
        {...{ open: showTimelineNode, setOpen: setShowTimelineNode }}
        className="relative w-full"
      >
        <div
          className="absolute z-10 top-4 right-4 rounded-full bg-black cursor-pointer"
          onClick={() => setShowTimelineNode(!showTimelineNode)}
        >
          <IoCloseCircle size="28" color="#fff" />
        </div>
        <div className="px-2 flex flex-col items-center justify-center w-full max-w-lg rounded-md">
          <CarouselMUI
            className="w-full p-2"
            data={
              isArray(selectNodeSubInfo?.images)
                ? selectNodeSubInfo?.images
                : []
            }
          />
          {selectTimelineNode?.followEvents?.length > 0 ? (
            <div className="flex flex-row px-2 py-2 self-start ">
              <div
                className="bg-purple-500 font-semibold rounded-md mr-2 px-2 py-1 text-xs text-white cursor-pointer"
                onClick={() => {
                  setSelectNodeSubInfo(selectTimelineNode);
                }}
              >
                {"ORIGIN"}
              </div>
              {selectTimelineNode?.followEvents?.map((fe, key) => {
                return (
                  <div
                    className="bg-purple-500 font-semibold rounded-md mr-2 px-2 py-1 text-xs text-white cursor-pointer"
                    onClick={() => {
                      setSelectNodeSubInfo(fe);
                    }}
                  >
                    {fe?.followEventType}
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="px-2 self-start">
            <div className="bg-red-500 font-semibold rounded-md px-2 py-1 text-xs text-white">
              {selectNodeSubInfo?.type}
            </div>
          </div>
          <div className="flex flex-col px-2">
            <div className="font-semibold text-lg px-2">
              {selectNodeSubInfo?.title}
            </div>
            {selectNodeSubInfo?.subtitle.map((item, key) => {
              let [title, value] = item.includes(":")
                ? item.split(":")
                : ["", item];
              const isH = value.includes("#");
              if (isH) {
                value = value.split("#")?.[1]?.trim();
              }
              return (
                <div key={key}>
                  <div className="px-2">
                    {/* <span>{key + 1}</span> */}
                    <span className="text-green-600 font-semibold">
                      {`${key + 1}. ${title}`}
                    </span>
                    {isH ? <h3>{value}</h3> : <span>{value}</span>}
                  </div>
                </div>
              );
            })}
            <div className="p-2 flex justify-center items-center">
              <ButtonMUI
                className="m-2"
                label="Remove Event"
                onClick={async () => {
                  if (!activeUser || !activeUser?.uid) {
                    toast.error("No activeUser");
                    return;
                  }

                  if (!selectTimelineNode?._id) {
                    toast.error("No event id");
                    return;
                  }

                  const resDel = await axios.post(
                    `${EVENT_SERVER_URL}/crypto/event/delete`,
                    {
                      uid: activeUser?.uid,
                      id: selectTimelineNode?._id,
                    }
                  );
                  if (resDel?.data?.success) {
                    setShowTimelineNode(false);
                    setCryptoEvent((ev) => {
                      return ev.filter(
                        (e) => e._id !== selectTimelineNode?._id
                      );
                    });
                    toast.success("Delete item successful!");
                  } else {
                    toast.error("Delete item failed!");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </WrapDialogMUI>
      <Block style={{ flexDirection: "column" }}>
        <Button onClick={() => setCryptoEventPage(cryptoEventPage + 1)}>
          Load more
        </Button>
        <Button onClick={() => setCryptoEventPage(cryptoEventPage - 1)}>
          Load less
        </Button>
      </Block>
    </>
  );
}

export default Event;
