import React from "react";
import TitlebarImageList from "../../elements/MUI/TitlebarImageListMUI";
import {
  JOEY_THE_PASSION,
  KAIBA_THE_REVENGE,
  YUGI_THE_DESTINY,
} from "../../constants/yugithedestiny.constants";

function YugiTheDestiny(props) {
  // const yugiCards = [
  //   {
  //     img: "/yugithedestiny/000002_card.png",
  //     title: "Image",
  //     author: "author",
  //   },
  // ];

  const yugiCards = YUGI_THE_DESTINY.map((name, key) => {
    const strId = String(Number(key) + 1).padStart(6, "0");
    return {
      img: `/yugithedestiny/${strId}_card.png`,
      title: name,
      id: strId,
      // author: "author",
    };
  });
  const kaibaCards = KAIBA_THE_REVENGE.map((name, key) => {
    const strId = String(Number(key) + 1).padStart(6, "0");
    return {
      img: `/kaibatherevenge/${strId}_card.png`,
      title: name,
      id: strId,
      // author: "author",
    };
  });
  const joeyCards = JOEY_THE_PASSION.map((name, key) => {
    const strId = String(Number(key) + 1).padStart(6, "0");
    return {
      img: `/joeythepassion/${strId}_card.png`,
      title: name,
      id: strId,
      // author: "author",
    };
  });

  return (
    <div className="flex flex-1 bg-white h-screen w-screen flex-col">
      <TitlebarImageList
        header="Yugi the Destiny"
        {...{ itemData: yugiCards }}
      />
      <TitlebarImageList
        header="Kaiba the Revenge"
        {...{ itemData: kaibaCards }}
      />
      <TitlebarImageList
        header="Joey the Passion"
        {...{ itemData: joeyCards }}
      />
    </div>
  );
}

export default YugiTheDestiny;
