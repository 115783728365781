import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Add, AddCircleOutline, CandlestickChart } from "@mui/icons-material";
import * as Lucide from "lucide-react";
import { Avatar } from "@mui/material";
import { IMAGE_DEFAULT } from "./constants";

export default function CustomizedTimeline(props) {
  const {
    title = "",
    data,
    clickTimelineDot = () => {},
    doubleClickTimelineDot = () => {},

    openModal,
    setOpenModal,
    showAddNode = false,
    alignLeft = false,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {title && <h3 className="p-4 font-bold">{title}</h3>}
      <Timeline
        sx={
          alignLeft && {
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }
        }
      >
        {/*position="alternate"*/}
        {data.map((item, key) => {
          const {
            datetime,
            title: titleItem,
            subtitle,
            color,
            bgColor,
            bColor,
          } = item || {};
          const image = item?.images[0] || IMAGE_DEFAULT;
          return (
            <TimelineItem key={key} position="right">
              <TimelineOppositeContent
                sx={{ m: "auto 0", p: 1, w: 54 }}
                variant="body2"
                color="text.secondary"
                // style={{
                //   width: 80,
                // }}
              >
                {
                  moment(datetime).format(
                    "DD-MM"
                  ) /*.format("DD-MM-YYYY kk:mm")*/
                }
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot
                  style={{
                    backgroundColor: bgColor,
                    color: color,
                    borderColor: bColor,
                    cursor: "pointer",
                  }}
                  onClick={() => clickTimelineDot(item)}
                  // onDoubleClick={() => doubleClickTimelineDot(item)}
                  variant="outlined"
                  // color="inherit"
                  //"inherit" | "grey" | "success" | "info" | "warning" | "primary" | "secondary"
                >
                  {image ? (
                    <Avatar alt="Main" src={image} />
                  ) : (
                    <div className="p-1 ">
                      <Lucide.CandlestickChart size={34} />
                    </div>
                  )}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                className="flex flex-col items-start justify-start"
                sx={{ py: "12px", px: 2 }}
              >
                <Typography variant="h6" component="span">
                  {titleItem}
                </Typography>
                {(typeof subtitle === "string" ? [subtitle] : subtitle).map(
                  (sub, key1) => {
                    const [label, content] = sub.includes(":")
                      ? sub.split(":")
                      : ["", sub];
                    let color = "#3f51b5";
                    const obj = {
                      "Kết quả": "#f44336",
                      "Lý do": "#6d28d9",
                    };
                    if (obj[label]) {
                      color = obj[label];
                    }
                    return (
                      <Typography key={key1}>
                        {label && (
                          <Typography
                            component="span"
                            color={color}
                            fontWeight={"bold"}
                          >
                            {label}:
                          </Typography>
                        )}
                        <Typography component="span">{content}</Typography>
                      </Typography>
                    );
                  }
                )}
              </TimelineContent>
            </TimelineItem>
          );
        })}
        {showAddNode && (
          <TimelineItem position="right">
            <TimelineOppositeContent
              sx={{ m: "auto 0", p: 1, w: 54 }}
              variant="body2"
              color="text.secondary"
            ></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot
                className="cursor-pointer p-2"
                color="primary"
                variant="outlined"
                onClick={() => setOpenModal(!openModal)}
              >
                <Add fontWeight="bold" fontSize="large" />
              </TimelineDot>
              <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "12px", px: 2 }}>
              <Typography variant="h6" component="span">
                Add new event
              </Typography>
              {/* <Typography>Because you need rest</Typography> */}
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>
    </div>
  );
}
